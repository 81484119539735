@import '~theme/viewport.module.css';

.HelpWithSearch {
  position: relative;
}

.HelpWithSearch-content {
  width: 100%;
}

.HelpWithSearch-generalEnquiryWrapper {
  @media (--viewport-lg) {
    width: 710px;
  }
}

.HelpWithSearch-trustpilot {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
  transition: color 0.2s ease;

  & .HelpWithSeach-quote {
    color: var(--color-neutral-900);
    font: var(--font-style-content-3);
    font-weight: var(--font-weight-medium);
  }

  & .HelpWithSearch-author {
    color: var(--color-neutral-900);
    font: var(--font-style-content-3);
    font-style: normal;
  }

  &:hover .HelpWithSeach-quote,
  .HelpWithSearch-author {
    color: var(--color-neutral-800);
  }

  &:active .HelpWithSeach-quote,
  .HelpWithSearch-author {
    color: var(--color-neutral-700);
  }
}

.HelpWithSearch-advisors {
  position: absolute;
  text-align: center;
  top: -24px;
  width: 100%;
}
