@import '~theme/viewport.module.css';

.ProductFeature-image {
  position: relative;
  width: 100%;
}

.ProductFeature-content {
  text-align: center;

  @media (--viewport-md) {
    text-align: left;
  }
}
