@import '~theme/typography.module.css';

.TestimonialCard {
  background-color: var(--color-white);
  border-radius: var(--space-md);
  box-shadow: var(--shadow-resting);
  color: var(--color-neutral-900);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: var(--space-lg);
}

.TestimonialCard--centered {
  margin: 0 auto;
}

.TestimonialCard-author {
  composes: u-typographyLabel1;
  margin-bottom: 0;
}

.TestimonialCard-authorTitle {
  font: var(--font-style-content-2);
}
