@import '~theme/viewport.module.css';

.SearchHeroForm-heading {
  text-align: center;

  @media (--viewport-lg) {
    text-align: initial;
  }
}

.SearchHeroForm-productTitle {
  color: var(--color-neutral-900);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-lineheight-md);
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.SearchHeroForm-horizontalRule {
  background-color: var(--color-neutral-100);
  border: 0;
  color: var(--color-neutral-100);
  height: 1px;
  margin-bottom: 0;
  margin-left: -10%;
  margin-right: -10%;
  margin-top: 0;
  width: 120%;
}

.SearchHeroForm-officeOptionContainer {
  border: 1px solid var(--color-neutral-500);
  border-radius: var(--space-xs);
  box-shadow: var(--shadow-subtle);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;

  /* apply a border-right to the first div child */
  & > div:first-child {
    border-right: 1px solid var(--color-neutral-500);
  }
}

.SearchHeroForm-officeOptionContainer-radio {
  align-items: center;
  color: var(--color-neutral-900);
  display: flex;
  font-size: var(--font-size-xs);
  margin-left: var(--space-md);
  min-height: calc(var(--space-base) * 14.5);
  padding: 2px calc(var(--space-base) * 3);
}

/*
  This is the autocomplete input.
  We usually want to avoid selecting a specific element like this, but in this case,
  because the input is on the Hero search, we want it to draw more
  attention, and we can't apply styles to the component itself.
*/
.SearchHeroForm-locationAutocomplete input {
  height: calc(var(--space-base) * 14.5);
}

/*
  This is ridiculous, don't do this anywhere else :D
  But really, the homepage hero is an exception, and I
  had to do this positioning to make the radio buttons
  align with the labels that are now smaller than our default
  labels.
*/
.SearchHeroForm-officeOptionContainer-radio input {
  + label::before {
    /* Distance from the top of the label in order to align with smaller label */
    top: 8px;
  }

  &:checked {
    + label::after {
      /* Used to centralise inner checked dot of button */
      top: 12px;
    }
  }

  @media (--viewport-md) {
    + label::before {
      /* Distance from the top of the label in order to align with smaller label */
      top: -6px;
    }

    &:checked {
      + label::after {
        /* Used to centralise inner checked dot of button */
        top: -2px;
      }
    }
  }
}

.SearchHeroForm-officeOptionContainer-radioLabel {
  display: block;

  @media (--viewport-md) {
    display: inline;
  }
}
