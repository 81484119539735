@import '~theme/viewport.module.css';

.Suggestions-list {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-neutral-100);
  box-shadow: var(--shadow-resting);
  list-style: none;
  margin-bottom: var(--space-md);
  margin-top: 0;
  padding-left: 0;
  width: 320px;

  @media (--viewport-lg) {
    width: auto;
  }
}

.Suggestions-button {
  color: var(--color-neutral-900);
  cursor: pointer;
  display: block;
  font: inherit;
  line-height: 1.3333;
  padding: var(--space-sm) var(--space-md);
  text-align: left;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-neutral-50);
    color: inherit;
    text-decoration: none;
  }

  > small {
    color: var(--color-neutral-400);
  }
}
