@import '~theme/viewport.module.css';

.PermanentOfficeSearch-content {
  @media (--viewport-md) {
    grid-column-end: span 4;
    text-align: center;
  }

  @media (--viewport-lg) {
    grid-column-end: span 4;
    text-align: left;
  }
}

.PermanentOfficeSearch-label {
  color: var(--color-brandprimary);
  font-family: var(--font-family);
  font-size: var(--font-size-xxs); /* 12px */
  font-weight: var(--font-weight-medium);
  letter-spacing: 1px;
  line-height: 1.3333px;
  margin-top: 10px;
  text-transform: uppercase;
}

.PermanentOfficeSearch-trustpilotContentWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  @media (--viewport-md) {
    justify-content: flex-start;
  }
}
