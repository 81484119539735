@import '~theme/viewport.module.css';

.Testimonial-carousel {
  align-items: center;
  display: flex;
}

.Testimonial-card {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  overflow: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100vw;

  @media (--viewport-lg) {
    align-items: stretch;
    align-self: stretch;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }
}

.Testimonial-figure {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 var(--space-md) 0 calc(var(--space-base) * 3);
  scroll-snap-align: center;
  width: 300px;
  z-index: 1;

  @media (--viewport-lg) {
    width: 350px;
  }
}
