.ProductCard-innerCard {
  display: flex;
  flex-direction: row;
  min-height: 160px;
}

.ProductCard-imageWrapper {
  flex: 0 0 124px;
  position: relative;
}

.ProductCard-innerContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: var(--space-md);
  width: 190px;
}

.ProductCard-ctaAlign {
  > span {
    align-items: center;
    display: flex;
  }
}

.ProductCard-spacer {
  flex-grow: 1;
}
