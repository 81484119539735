@import '~theme/viewport.module.css';

.HelpWithSearch-wrapper {
  align-items: center;
  color: var(--color-neutral-900);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  @media (--viewport-lg) {
    align-items: start;
    text-align: left;
  }
}

.HelpWithSearch-generalEnquiryWrapper {
  @media (--viewport-lg) {
    width: 710px;
  }
}

.HelpWithSearch-generalEnquiryButton {
  color: var(--color-brandprimary);
  cursor: pointer;
  min-height: 30px; /* Prevents height from fluctuating in loading state */
  padding: 0;

  &:active,
  &.sb__active,
  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover {
    /* Reset default <a> styles */
    text-decoration: none;
    transition-duration: var(--animation-short);
    transition-property: color;
    transition-timing-function: linear;
  }

  &:active,
  &.sb__active {
    color: var(--color-brandprimary-active);
  }

  &:hover,
  &.sb__hover,
  &:focus,
  &.sb__focus {
    color: var(--color-brandprimary-hover);
  }
}

.HelpWithSearch-generalEnquiryButton--loading {
  color: inherit;

  &:active,
  &.sb__active,
  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover {
    color: inherit;
  }
}

.HelpWithSearch-ctaWrapper {
  padding-left: 0;

  @media (--viewport-lg) {
    border-left: 2px solid var(--color-brandsecondary);
    padding-left: var(--space-lg);
  }
}

.HelpWithSearch-advisoryImageDesktop {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.HelpWithSearch-advisoryImageMobile {
  display: block;
  text-align: center;

  @media (--viewport-md) {
    display: none;
  }
}
