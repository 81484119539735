.OnDemandForTeam-quote {
  text-align: left;
}

.OnDemandForTeam-quoteCredit {
  font-weight: bold;
}

.OnDemandForTeam-statsContext {
  font-weight: 100;
}

.OnDemandForTeam-pitchItem {
  font: var(--font-style-content-2);
}
