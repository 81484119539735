@import '~theme/viewport.module.css';

.Home-searchHeroWrapper {
  padding: var(--space-lg) 0 0 0;

  @media (--viewport-lg) {
    padding: var(--space-xxl) 0;
  }
}

.Home-testimonials {
  background-color: var(--color-brandsecondary);
  padding: var(--space-xxl) 0;
}

.Home-helpWithSearchCta {
  color: var(--color-neutral-900);
  position: relative;
  z-index: 1;
}

.Home-curve {
  margin-top: -50px;
  position: relative;
  z-index: 0;
}

.u-diplayBelowLg {
  display: block;

  @media (--viewport-lg) {
    display: none;
  }
}

.u-displayAboveLg {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
