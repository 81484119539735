@import '~theme/viewport.module.css';

.CompanyLogos {
  align-items: center;
  display: grid;
  grid-auto-rows: calc(var(--space-base) * 24); /* 96px */
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  @media (--viewport-lg) {
    display: var(--is-grid);
    grid-template-columns: repeat(var(--no-of-columns), 1fr);
    justify-content: space-around;
    min-height: calc(var(--space-base) * 26); /* 104px */
  }
}

.CompanyLogos-item {
  text-align: center;

  @media (--viewport-lg) {
    align-self: var(--align-self-default, center);

    &:nth-child(odd) {
      align-self: var(--align-self-odd, center);
    }
  }
}

.CompanyLogos-image {
  max-height: calc(var(--space-base) * 16); /* 64px */
  max-width: 100%;
}
