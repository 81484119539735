@import '~theme/viewport.module.css';

.SearchHero-heading {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.SearchHero-headingMobile {
  @media (--viewport-sm) {
    display: block;
  }

  @media (--viewport-lg) {
    display: none;
  }
}

.SearchHero-images {
  display: none;

  @media (--viewport-lg) {
    border-radius: 8px;
    display: block;
  }
}

.SearchHero-imagesColumnLeft {
  display: none;

  @media (--viewport-lg) {
    display: flex;
    flex-direction: column;
    margin-right: var(--space-sm);
  }
}

.SearchHero-imagesColumnRight {
  display: none;

  @media (--viewport-lg) {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-lg);
  }
}

.SearchHero-imagesGrid {
  display: none;

  @media (--viewport-xl) {
    display: flex;
    flex-direction: row;
  }
}
