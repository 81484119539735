@import '~theme/viewport.module.css';

.UspCards {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-sm);
  justify-content: center;

  @media (--viewport-md) {
    gap: var(--space-sm);
    justify-content: start;
  }
}
